import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import carouselData from "../data/carouselData.json";
import "./styles/Carousel.css";

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const startSwipeX = useRef(null);

  const handleStartSwipe = (e) => {
    startSwipeX.current = getClientX(e);
  };

  const handleEndSwipe = (e) => {
    if (startSwipeX.current === null) return;
    const endSwipeX = getClientX(e);
    const deltaX = startSwipeX.current - endSwipeX;
    console.log("Delta X:", deltaX);
    if (Math.abs(deltaX) > 50) {
      if (deltaX > 150) nextSlide();
      else prevSlide();
    }

    startSwipeX.current = null;
  };

  const getClientX = (e) => {
    if (e.clientX) return e.clientX;
    if (e.touches && e.touches.length) return e.touches[0].clientX;
    return null;
  };

  const nextSlide = () => {
    let nextIndex;
    if (activeIndex === carouselData.length - 3) {
      nextIndex = 0; // Go back to the first slide
    } else {
      nextIndex = activeIndex + 1;
    }
    setActiveIndex(nextIndex);
  };

  const prevSlide = () => {
    const prevIndex =
      activeIndex === 0 ? carouselData.length - 3 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  return (
    <div className="carousel-page">
      <p className="carousel-headline">We craft great products!</p>
      <div className="overflowTest">
        <div
          ref={containerRef}
          className="carousel-container"
          style={{ transform: `translateX(-${activeIndex * 300}px)` }}
          onMouseDown={handleStartSwipe}
          onMouseUp={handleEndSwipe}
          onTouchStart={handleStartSwipe}
          onTouchEnd={handleEndSwipe}
        >
          {carouselData.map((item, index) => (
            <Link to={`/product/${item.id}`} key={item.id} className="link-style">
              <div
                className={`carousel-card ${index === activeIndex ? "active" : ""}`}
                onMouseDown={handleStartSwipe}
                onMouseUp={handleEndSwipe}
                onTouchStart={handleStartSwipe}
                onTouchEnd={handleEndSwipe}
              >
                <img src={item.imageUrl} alt={item.title} />
                <div className="card-description">
                  <p className="card-name">{item.title}</p>
                  <p className="card-text">{item.text}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="carousel-buttons">
        <button className="arrow-button" onClick={prevSlide}>
          <i className="fa-regular fa-arrow-left"></i>
        </button>
        <button className="arrow-button" onClick={nextSlide}>
          <i className="fa-regular fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
