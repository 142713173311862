import React from "react";
import "../Product.css";
import CustomCarousel from "../../custom.slider";
import Footer from "../../Footer";
import productData from '../../../data/industrial.json';
import "./SecureUsb.css";


function Vibration() {
  const product = productData.find(item => item.id === "vibration");

  if (!product) {
    return <div>Product not found!</div>;
  }

  return (
    <div className="product-page">
      <div className="product">
        <div className="product-content">
          <div className="product-description">
            <p className="product-name">{product.headline}</p>
            <div className="product-text">
              {product.paragraphs.map((paragraph, index) => (
                <div key={index}>
                  <p className="title-name">{paragraph.title}</p>
                  <p>{paragraph.content}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="singlePic"> {".container__slider__links "}
            <div className="img-slide">
              <CustomCarousel>
                {product.images.map((image, index) => (
                  <img key={index} src={image.imgURL} alt={image.imgAlt} />
                ))}
              </CustomCarousel>
            </div>
          </div>
        </div>
      </div>
      <img src="/images/Vibration/bottom.webp" className="singlepic" alt="Bottom" />
      <Footer />
    </div>
  );
}

export default Vibration;
