import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import Projects from "./components/pages/Projects";
import Product from "./components/pages/Product"; // Import Product component
import AboutUsfull from "./components/pages/AboutUsfull";
import TheMoonProduct from "./components/pages/products/TheMoonProduct";
import SmartThermostatProduct from "./components/pages/products/SmartThermostat";
import EncryptedCommunications from "./components/pages/products/EncryptedCommunications";
import HighPrecision from "./components/pages/products/HighPrecision";
import SecureUsb from "./components/pages/products/SecureUsb";
import Vibration from "./components/pages/products/Vibration";
import Exoskeleton from "./components/pages/products/Exoskeleton";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/product/theMoon" element={<TheMoonProduct />} /> 
          <Route path="/product/SmartThermo" element={<SmartThermostatProduct />} /> 
          <Route path="/product/EncryptedCommunications" element={<EncryptedCommunications />} /> 
          <Route path="/product/HighPrecision" element={<HighPrecision />} />
          <Route path="/product/SecureUsb" element={<SecureUsb />} />
          <Route path="/product/vibration" element={<Vibration />} />
          <Route path="/product/Exoskeleton" element={<Exoskeleton />} />
          <Route path="/aboutUs" exact element={<AboutUsfull />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
