import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "../../App.css";
import "./Projects.css";
import Footer from "../Footer";
import ZzgZgSection from "../ZzgZg.js"; 
import missionStatement from "../../data/Projectsdata.json"; 

function Projects() {
  const [activeSection, setActiveSection] = useState('RETAIL');
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    handleSectionChange(activeSection);
  }, [activeSection]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    
    setSectionData([]);
    import(`../../data/${section.toLowerCase()}.json`)
      .then((data) => setSectionData(data))
      .catch((error) => console.error("Error loading section data:", error));
  }

  const renderSectionContent = () => {
    switch (activeSection) {
      case 'MILITARY':
      case 'MEDICAL':
      case 'RETAIL':
      case 'INDUSTRIAL':
      case 'PRIVATE':
      
        return <ZzgZgSection data={sectionData} />;
      default:
        return (
          <div>
            {/* Default content for ALL PROJECTS section */}
          </div>
        );
    }
  }

  return (
    <div className="projects">
      <div className="projects-description">
        <div className="projects-title">
          {missionStatement.title}
        </div>
        <div className="projects-text">
          {missionStatement.content.map((paragraph, index) => (
            <React.Fragment key={index}>
              <p>{paragraph}</p>
              {index === 0 && <br />} 
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="section-buttons">
        <button
          className={activeSection === 'RETAIL' ? 'active-button' : 'inactive-button'}
          onClick={() => handleSectionChange('RETAIL')}
        >
          RETAIL
        </button>
        <button
          className={activeSection === 'MILITARY' ? 'active-button' : 'inactive-button'}
          onClick={() => handleSectionChange('MILITARY')}
        >
          MILITARY
        </button>
        <button
          className={activeSection === 'INDUSTRIAL' ? 'active-button' : 'inactive-button'}
          onClick={() => handleSectionChange('INDUSTRIAL')}
        >
          INDUSTRIAL
        </button>
        <button
          className={activeSection === 'MEDICAL' ? 'active-button' : 'inactive-button'}
          onClick={() => handleSectionChange('MEDICAL')}
        >
          MEDICAL
        </button>
        <button
          className={activeSection === 'PRIVATE' ? 'active-button' : 'inactive-button'}
          onClick={() => handleSectionChange('PRIVATE')}
        >
          PRIVATE
        </button>
       
      </div>
      <div className="section-content">
        {activeSection !== '' && renderSectionContent()}
      </div>
      <Footer />
    </div>
  );
}

export default Projects;
