import React from "react";
import "./styles/Runningline.css";

function Runningline({ words = [] }) {
  return (
    <div className="header ticker-wrapper" style={{ '--word-count': words.length }}>
      <div className="ticker-wrapper__first-half">
        {words.slice(0, Math.ceil(words.length / 2)).map((word, index) => (
          <p key={index}>{word}</p>
        ))}
      </div>
      <div className="ticker-wrapper__second-half">
        {words.slice(Math.ceil(words.length / 2)).map((word, index) => (
          <p key={index}>{word}</p>
        ))}
      </div>
    </div>
  );
}


export default Runningline;
