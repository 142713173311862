import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/Navbar.css";

function Navbar() {
  const { pathname } = useLocation();
  const handleQuoteRequest = () => {
    window.location.href =
      "mailto:your@email.com?subject=Quote%20Request&body=Hello,%0D%0A%0D%0AI would like to request a quote for...";
  };

  // State variables
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Function to handle click event
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // Function to show/hide button based on window width
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    // Add event listeners
    window.addEventListener("resize", showButton);
    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("resize", showButton);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Update navbar background dynamically based on scroll position
  const navbarClass = scrollPosition > 0 ? "navbar scrolled" : "navbar";

  return (
    <>
      <nav className={navbarClass}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img
              src="/images/Frame 6.svg"
              alt="HardwareProject Logo"
              className="navbar-logo"
            />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className={`nav-links ${pathname === "/" ? "active" : ""}`}
                onClick={closeMobileMenu}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/projects"
                className={`nav-links ${
                  pathname === "/projects" ? "active" : ""
                }`}
                onClick={closeMobileMenu}
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/services"
                className={`nav-links ${
                  pathname === "/services" ? "active" : ""
                }`}
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/aboutUs"
                className={`nav-links ${
                  pathname === "/aboutUs" ? "active" : ""
                }`}
                onClick={closeMobileMenu}
              >
                About Us
              </Link>
            </li>
            
            <li>
              <a
                href="mailto:your@email.com?subject=Quote%20Request&body=Hello,%0D%0A%0D%0AI would like to request a quote for..."
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                Request Quote
              </a>
            </li>
          </ul>
          {button && (
            <a className="random-class ribbon-outset border requestQuote" onClick={handleQuoteRequest}>
              Request Quote
            </a>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
