import React from "react";
import { Link } from 'react-router-dom';
import "./styles/ZzgZgSection.css"; 

function ZzgZgSection({ data }) {
 
  const products = Object.values(data);

  return (
    <div className="zzgzg-section">
      {products.map((product, index) => (
        <Link to={`/product/${product.id}`} className={`zzgzg-item ${index % 2 === 1 ? 'reversed' : ''}`} key={product.id}>
          <div className="zzgzg-content">
            <div className="zzgzg-headline">
              <p>{product.headline}</p>
            </div>
            <div className="zzgzg-text">
              <p>{product.text}</p>
            </div>
          </div>
          {product.image && (
            <div className="zzgzg-image">
              <img src={product.image} alt="Project" />
            </div>
          )}
        </Link>
      ))}
    </div>
  );
}

export default ZzgZgSection;
