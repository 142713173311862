import React from "react";
import HeroSection from "../HeroSection";
import Second from "../Second";
import Carousel from "../Carousel";
import Zigzag from "../Zigzag";
import Fourth from '../Fourth';
import Runningline from "../Runningline";
import Aboutus from "../Aboutus";
import Getintouch from "../Getintouch";
import Footer from "../Footer";

function Home() {
  return (
    <div style={{ background: 'linear-gradient(180deg, rgba(7,7,31,1) 0%, rgba(7,7,31,1) 10%, rgba(13,19,26,1) 20%, rgba(21,35,56,1) 31%, rgba(21,35,56,1) 40%, rgb(13, 22, 45) 54%, rgba(30,51,101,1) 69%, rgba(30,51,101,1) 81%, rgba(30,51,101,1) 94%)' }}> 
      <HeroSection />
      <Runningline words={["HARDWARE", "DESIGN", "SERVICES", "•",]} />
      <Second />
      <Carousel />
      <Fourth />
      <Zigzag />
      <Aboutus />
     
      <Footer />
    </div>
  );
}

export default Home;
