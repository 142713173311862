import React from "react";
import "./styles/Fourth.css";
function Fourth() {
  return (
    <div className="fourthcontainer">
      <p className="title">From Concept <br />
       To a World-Class Product!<div className="oval"></div></p>
       
      <p className="mission">
        Our mission is to make your life easier by providing innovative
        solutions that simplify your everyday tasks. We understand the demands
        of a fast-paced world and the challenges you face in navigating through
        it.<br />
        That's why we are dedicated to developing products and services that
        easy processes, increase efficiency, and improve the quality of your
        life.
        <br /> <br />
        Discover how hardware design house can simplify your daily routines,
        streamline your processes, and empower you to achieve more with ease. We
        believe in the power of simplicity and convenience. With just one touch,
        we strive to make your life easier and more enjoyable. We understand
        that your time and energy are precious, and we aim to provide smart
        solutions. We recognize that everyone's needs and preferences are
        unique. Through intelligent algorithms and automated processes, we aim
        to reduce manual effort and empower you to accomplish tasks more
        efficiently.
      </p>
    </div>
  );
}

export default Fourth;
