import React from 'react';
import { Link ,useLocation } from "react-router-dom";
import '../App.css';
import './styles/HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <div className='jj'>
        <p className='welcome'>We Turn Imaginations into <div className='real'>Reality</div></p>
        <div className='hero-btns'>
          <Link to="/services">
            <button className='about-button'>Read More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
