import React from "react";
import "./styles/Footer.css";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-container">
        <img src="../images/Group 207.svg" />
        <div className="footer-text">
          <div className="text-slogan">
            <p>We are SMART IN FLOW CONTROL.</p>
            <p>Let us show you how.</p>
          </div>
          <div className="text-contact">
            <p>Call Us:</p>
            <p>+374 55 342 262 | +374 41 777 704</p>
          </div>
          <div className="text-contact">
            <p>Email Us:</p>
            <p><a href="mailto:info@hardwarehouses.com">info@hardwarehouses.com</a></p>
          </div>
        </div>
      </div>
      <div className="bottom-side">
      <p className="underpage-text">
      © Hardware Design House {currentYear}
      </p>
      <div className="toTop"><p>BACK TO TOP</p>
      <a onClick={scrollToTop} className="go-to-top-button">
      <i class="fa-solid fa-arrow-up"></i>
      </a>
      </div>
      </div>
    </div>
  );
}

export default Footer;
