import React from "react";
import "../styles/Aboutus.css";
import Footer from "../Footer"; 
import AboutusData from "../../data/AboutUsfulldata.json";
import "./AboutUsfull.css";

function AboutUsfull() {
  
  const descriptionParagraphs = AboutusData["about-wrapper"].about[
    "about-description"
  ].split("\n\n");

  return (
    <div className="about-full">
      
      <div className="about-wrapper">
        <div className="about">
          <p className="aboutFull-headline">
            {AboutusData["about-wrapper"].about.headline}
          </p>
          <p className="about-full-description">
            
            {descriptionParagraphs.map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                <br />
                <br />
              </React.Fragment>
            ))}
          </p>
          <ul>
            {AboutusData["about-wrapper"].about["core-values"].map(
              (value, index) => (
                <li key={index}>{value}</li>
              )
            )}
          </ul>
        <div className="aboutFull-cards">
          {AboutusData["about-wrapper"]["about-cards"]["about-card"].map(
            (card, index) => (
              <div className="about-card" key={index}>
                <img
                  className="card-image"
                  src={card.image}
                  alt={card.alt}
                />
                <div className="description-container">
                  <p className="card-name">
                    {card.name}  <a
                      href={card.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin" style={{ color: "white" }}></i>
                    </a>
                  </p>{" "}
                  <br />
                  <p>{card.role}</p>
                </div>
              </div>
            )
          )}
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUsfull;
