import React from "react";
import "../App.css";
import "./styles/Second.css";

function Second() {
  return (
    <div className="esim">
    <div className="second">
      <p className="paragraph">
        Electronics - to set the project up for success, we start with the
        basics – electronic system and circuit design. This lays the groundwork
        for the next critical implementation stages: PCB design and simulation,
        board sample production, trial production and mass-production. 
      </p>
      <img src="../images/Frame 90.svg" className="brain"/>
    </div>
    <div className="pictures">
      <img src="../images/Frame 92.svg" className="layout"/>
      <img src="../images/Frame 93.svg" className="fpga"/>
      <img src="../images/Frame 97.svg" className="electronics"/>
      <img src="../images/Frame 95.svg" className="simulations"/>
      <img src="../images/Frame 96.svg" className="enclosure"/>
      </div>
    </div>
  );
}

export default Second;
