import React, { useState } from "react";
import CustomCarousel from "../custom.slider";
import Footer from "../Footer";
import "./Product.css";
import productData from '../../data/retail.json';

function Product() {
  const [error, setError] = useState(null); 

  if (!productData || productData.length === 0) {
    return <div>Loading...</div>;
  }

  const product = productData[0]; 

  return (
    <div className="product-page">
      <div className="product">
        <div className="product-content">
          <div className="product-description">
            <p className="product-name">{product.headline}</p>
            <div className="product-text">
              <p>{product.text}</p>
              <ul>
                {product.features.map((feature, index) => (
                  <li key={index} className="lili">{feature}</li>
                ))}
              </ul>
            </div>
            {/* <div className="category">
              <p>Category:</p>
              {product.categories.map((category, index) => (
                <p key={index} className="category-item">{category}</p>
              ))}
            </div> */}
          </div>
          <div className="img-slide">
            <CustomCarousel>
              {product.images.map((image, index) => (
                <img key={index} src={image.imgURL} alt={image.imgAlt} />
              ))}
            </CustomCarousel>
          </div>
        </div>
      </div>
      <div className="photoArray">
        {product.additionalImages.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Additional Image ${index + 1}`} />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Product;
