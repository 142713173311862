import React from "react";
import "./styles/Aboutus.css";
import AboutusData from "../data/Aboutusdata.json";
import { Link } from "react-router-dom";

function Aboutus() {
  return (
    <div className="about-wrapper">
      
      <div className="about">
        <p className="about-headline">
          {AboutusData["about-wrapper"].about.headline}
        </p>
        <p className="about-description">
          {AboutusData["about-wrapper"].about["about-description"]}
        </p>
        <ul>
          {AboutusData["about-wrapper"].about["core-values"].map(
            (value, index) => (
              <li key={index} className="lili">{value}</li>
            )
          )}
        </ul>
        <Link to='/aboutUs'>
          <button
            className={`about-button ${AboutusData["about-wrapper"].button.className}`}
          >
            {AboutusData["about-wrapper"].button.text}
          </button>
        </Link>
      </div>
      <div className="about-cards">
        {AboutusData["about-wrapper"]["about-cards"]["about-card"].map(
          (card, index) => (
            <div className="about-card" key={index}>
              <img className="card-image" src={card.image} alt={card.alt} />
              <div className="description-container">
  <p className="card-name">
    {card.name}{" "}
    <a
      href={card.linkedin}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "white" }}
    >
      <i className="fab fa-linkedin"></i>
    </a>
  </p>{" "}
  <br />
  <p>{card.role}</p>
</div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Aboutus;
