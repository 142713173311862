import React from "react";
import "../Product.css";
import CustomCarousel from "../../custom.slider";
import Footer from "../../Footer";
import productData from '../../../data/military.json';
import "./EncryptedCommunications.css"

function HighPrecision() {
  const product = productData.find(item => item.id === "HighPrecision");

  if (!product) {
    return <div>Product not found!</div>;
  }

  return (
    <div className="product-page">
      <div className="product">
        <div className="product-content">
          <div className="product-description">
            <p className="product-name">{product.headline}</p>
            <div className="product-text">
              {product.paragraphs.map((paragraph, index) => (
                <div key={index}>
                  <p className="title-name">{paragraph.title}</p>
                  <p>{paragraph.content}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="singlePic"> 
          <div className="img-slide">
            <CustomCarousel>
              {product.images.map((image, index) => (
                <img key={index} src={image.imgURL} alt={image.imgAlt} />
              ))}
            </CustomCarousel>
          </div>
          </div>
        </div>
      </div>
      <div className="photoArray">
        {product.additionalImages && (
          product.additionalImages.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Additional Image ${index + 1}`} />
            </div>
          ))
        )}
      </div>
      <Footer />
    </div>
  );
}

export default HighPrecision;
