import React from "react";
import "./styles/Zigzag.css";
import Zigzagdata from "../data/Zigzagdata.json";

function Zigzag() {
  return (
    <div className="zigzag">
      <div className="zigzag-container">
        {Zigzagdata.zigzag["zigzag-container"]["zigzag-item"].map((item, index) => (
          <div className="zigzag-item" key={index}>
            <p>
              <p className="headline">{item.headline}</p>
              {item.text}
            </p>
            <img src={item.image.src} alt={item.image.alt} height={item.image.height} />
          </div>
        ))}
      </div>
      <div className="bgrnd" style={{backgroundImage: `url(${Zigzagdata.zigzag.bgrnd})`}}>
        <div className="second-container">
          <p className="second-headline">
            {Zigzagdata.zigzag["second-container"]["second-headline"]}
          </p>
        </div>
        <div className="list">
          {Zigzagdata.zigzag["second-container"]["list"]["wrapper"].map((wrapper, index) => (
            <div className="wrapper" key={index}>
              {wrapper.descr.map((descr, idx) => (
                <p className="descr" key={idx}>
                  <p className="descr-name">
                     {descr["descr-name"]}
                  </p>
                  {descr.text}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="focus">
        
        <p className="third-headline">{Zigzagdata.zigzag.focus["third-headline"]}</p>
        <div className="list">
          {Zigzagdata.zigzag.focus.list.wrapper.map((wrapper, index) => (
            <div className="wrapper" key={index}>
              {wrapper.descr.map((descr, idx) => (
                <p className="descr" key={idx}>
                  <p className="descr-name">
                     {descr["descr-name"]}
                  </p>
                  {descr.text}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Zigzag;
