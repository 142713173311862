import React from "react";
import "../../App.css";
import "./Services.css";
import Footer from "../Footer";

function Services() {
  const servicesData = [
    {
      title: "Electronics",
      description:
        "To set the project up for success, we start with the basics – electronic system and circuit design. This lays the groundwork for the next critical implementation stages: PCB design and simulation, board sample production, trial production and mass-production.",
      image: "../images/Service1.svg"
    },
    {
      title: "PCB Layout",
      description:
        "PCB design is an essential stage in the hardware design process. Our team of PCB design and layout engineers deliver reliable and cost-effective custom PCB and IPC-qualified embedded hardware solutions.",
      image: "../images/Service2.svg"
    },
    {
      title: "PCB Simulations",
      description:
        "We apply a comprehensive suite of PCB analysis and verification techniques starting in the design stage. This increases product performance, eliminates layout iterations, and gets your solution to market faster.",
      image: "../images/Service3.svg"
    },
    {
      title: "FPGA",
      description:
        "As part of our FPGA design services, we offer programmable architecture, microprocessors, and filters, as well as phase-locked loops integrated into a high density single chip.",
      image: "../images/Service4.svg"
    },
    {
      title: "Enclosure",
      description:
        "We will create a 3D design and 3D model of the required enclosure, verify that the sample meets all the ergonomics, assemblability, durability, flexibility, shock and water resistance requirements, and coordinate the mass production.",
      image: "../images/Service5.svg"
    }
  ];

  return (
    <div className="services">
      <div className="top-side">
      <p className="offer">We offer smart solution</p>
      <div className="squares">
        {servicesData.map((service, index) => (
          <div className="xlinq" key={index}>
            <div className="square">{`0${index + 1}`}</div>
            <p className="square-title">{service.title}</p>
          </div>
        ))}
      </div>
        </div>
      <div className="wrappers">
        {servicesData.map((service, index) => (
          <div className={index % 2 === 0 ? "element" : "element-alt"} key={index}>
            <div>
              <p className="service-headline">{service.title}</p>
              <p className="service-text">{service.description}</p>
            </div>
            <img src={service.image} alt={service.title} />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Services;
